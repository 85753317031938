<template>
  <!-- 产品分类 -->
  <div
    id="Acategory"
    v-loading="loading"
  :element-loading-text="downloadRole ? this.$t(`message.loading.downloading`) : loading ? this.$t(`message.loading.suanfa`) : downloadRole ? this.$t(`message.loading.downloadcompletes`) : this.$t(`message.loading.Successsuanfa`)"
    style="width: 100%">
    <div :class="$store.state.lang == 'en' ? 'english' : ''">
      <Header :info="info"></Header>
      <!-- 选择项目\选择产品 -->
      <div class="header_bar">
        <!-- 第一行 -->
        <div class="bar_One">
          <div class="Page_title">{{ $t(`message.Category.page2.Title`) }}
            <div class="line"></div>
          </div>
          <!-- 选择品类 -->
          <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item :label="$t(`message.Category.common.Category`)" class="bar_One_text_0">
              <el-select v-model="product" :placeholder="$t(`message.Category.page4.SelectOne`)" @change="changeName">
                <el-option v-for="(item, index) in category_list" :key="index" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <!-- 感官对比。            。。。。。 -->
          <div class="block">
            <span class="demonstration">{{ $t(`message.Category.common.Products`) }}</span>
            <el-cascader
              v-model="contrast"
              :value="values"
              :placeholder="$t(`message.Category.common.Select`)"
              :options="options"
              :show-all-levels="false"
              :props="{ multiple: true, emitPath: true }"
              filterable
              ref="cascaderAddr"
              clearable
              @change="Cvalue"
              :label="label"></el-cascader>
          </div>
          <!-- 中文显示 -->
          <div class="button" v-show="$store.state.lang == 'cn'">
            <div class="rest" @click="clear_num">
              <img src="https://newstore.vynior.com/rest_choose.png" alt="" />
            </div>

            <div class="compare">
              <img id="downloadImg" @click="downloadShow = !downloadShow" src="https://newstore.vynior.com/download.png" alt="" />
              <div class="download-select" v-show="downloadShow">
                <div class="select-item" @click="downloadIMG()">单张下载</div>
                <div class="select-item" @click="downloadIMGALL()">全部下载</div>
              </div>
            </div>

            <div class="port" @click="req_ana">
              <img src="https://newstore.vynior.com/ksfx.png" alt="" />
            </div>
          </div>
          <!-- 英文显示 -->
          <div class="button" v-show="$store.state.lang == 'en'">
            <div class="rest">
              <img src="https://newstore.vynior.com/rrrrssst.png" alt="" @click="clear_num" />
            </div>

            <div class="compare">
              <img id="downloadImg" @click="downloadShow = !downloadShow" src="https://newstore.vynior.com/xzxz.png" alt="" />
              <div class="download-select" v-show="downloadShow">
                <div class="select-item" @click="downloadIMG()">{{ $t(`message.Category.common.DownloadGraph`) }}</div>
                <div class="select-item" @click="downloadIMGALL()">{{ $t(`message.Category.common.DownloadAll`) }}</div>
              </div>
            </div>

            <div class="port">
              <img src="https://newstore.vynior.com/fenxifx.png" alt="" @click="req_ana" />
            </div>
          </div>
        </div>
      </div>
      <!-- 下面的内容box -->
      <div class="Content_box">
        <div class="Product_title" :class="change_ === false ? 'change_show' : ''">
          <div class="Product_title-t">
            <div class="line1">
              <div class="pdc_tip">{{ $t(`message.Category.page2.Label`) }}</div>
              <div class="switch">
                <el-switch v-model="value" :disabled="change_" active-color="#13ce66" inactive-color="#ff4949"> </el-switch>
              </div>
            </div>
            <div class="line2" v-show="not_img == 1 || showCollapse == false">
              <ul>
                <!-- <li>
              <el-radio v-model="radio" :disabled="change_" label="1"
                >消费者调查数据</el-radio
              >
            </li> -->
                <li>
                  <el-radio v-model="radio" :disabled="change_" label="2">{{ $t(`message.Category.page2.SPF`) }}</el-radio>
                </li>
                <li class="li2">
                  <el-radio v-model="radio" :disabled="change_" label="3">{{ $t(`message.Category.page2.Formulation`) }}</el-radio>
                </li>
              </ul>
            </div>
            <div
              v-show="not_img == 0 && showCollapse == true"
              :class="
                seletshow == 2
                  ? radio == 2
                    ? 'collapse collapse-click collapse-show'
                    : 'collapse collapse-show'
                  : radio == 2
                  ? seletshow != 2
                    ? 'collapse collapse-click'
                    : 'collapse collapse-click collapse-show'
                  : 'collapse'
              ">
              <div class="collapse-button">
                <el-radio v-model="radio" :label="2" @click.native.prevent="radioChange(2)">{{ $t(`message.Category.page2.SPF`) }}</el-radio>
                <i
                  :class="
                    seletshow == 2 || radio == 2
                      ? 'el-collapse-item__arrow el-icon-arrow-right is-active'
                      : 'el-icon-arrow-right el-collapse-item__arrow'
                  "
                  @click="seletshow == 2 ? (seletshow = 0) : (seletshow = 2)"></i>
              </div>
              <div class="text2" v-show="seletshow == 2 || radio == 2">
                <div class="radio1 radio">
                  <div class="circle"></div>
                  <p>SPF＜30</p>
                </div>
                <div class="radio2 radio">
                  <div class="circle"></div>
                  <p>30≤SPF＜50</p>
                </div>
                <div class="radio3 radio">
                  <div class="circle"></div>
                  <p>SPF≥50</p>
                </div>
                <div class="radio4 radio">
                  <div class="circle"></div>
                  <p>{{ $t(`message.Category.page2.none`) }}</p>
                </div>
              </div>
            </div>
            <div
              v-show="not_img == 0 && showCollapse == true"
              :class="
                seletshow == 3
                  ? radio == 3
                    ? 'collapse collapse-click collapse1-show'
                    : 'collapse collapse1-show'
                  : radio == 3
                  ? seletshow != 3
                    ? 'collapse collapse-click'
                    : 'collapse collapse-click collapse1-show'
                  : 'collapse2 '
              ">
              <div class="collapse-button">
                <el-radio v-model="radio" :label="3" @click.native.prevent="radioChange(3)">{{ $t(`message.Category.page2.Formulation`) }}</el-radio>
                <i
                  :class="
                    seletshow == 3 || radio == 3
                      ? 'el-collapse-item__arrow el-icon-arrow-right is-active'
                      : 'el-icon-arrow-right el-collapse-item__arrow'
                  "
                  @click="seletshow == 3 ? (seletshow = 0) : (seletshow = 3)"></i>
              </div>

              <div class="text3" v-show="seletshow == 3 || radio == 3">
                <div class="radio">
                  <div class="radio1">
                    <div class="circle"></div>
                    <p>{{ $t(`message.Category.page2.Physical`) }}</p>
                  </div>
                  <div class="radio2">
                    <div class="circle"></div>
                    <p>{{ $t(`message.Category.page2.Chemical`) }}</p>
                  </div>
                </div>
                <div class="radio3">
                  <div class="circle"></div>
                  <p>{{ $t(`message.Category.page2.Hybrid`) }}</p>
                </div>
              </div>
            </div>
          </div>
          <!-- 下拉 -->
          <!-- <el-collapse
          accordion
          v-show="not_img == 0 && showCollapse == true"
          v-model="activeNames"
          :disabled="showCollapse"
          @change="changeRadio(activeNames)"
        >
          <el-collapse-item name="1">
            <template slot="title" style="background-color: pink;">
              <div class="selectStyle">
                <el-radio v-model="radio"  :disabled="change_" label="1"
                  ></el-radio
                >
                <span :style="{ color: radio == 1 ? 'rgba(29, 92, 166, 1)' : 'rgba(24, 26, 29, 1)' }">消费者调查数据</span>
              </div>
            </template>
            下拉内容
            <div class="text1">
              <div class="radio1">
                <div class="circle"></div>
                <p>有</p>
              </div>
              <div class="radio2">
                <div class="circle"></div>
                <p>无</p>
              </div>
            </div>
          </el-collapse-item>

          <el-collapse-item name="2">
            <template slot="title">
              <div class="selectStyle">
                <el-radio
                  v-model="radio"
                  :disabled="change_"
                  label="2"
                ></el-radio>
                <span
                  :style="{
                    color:
                      radio == 2
                        ? 'rgba(29, 92, 166, 1)'
                        : 'rgba(24, 26, 29, 1)',
                  }"
                  >SPF数值</span
                >
              </div>
            </template>
            <div>
              <div class="text2">
                <div class="radio1">
                  <div class="circle"></div>
                  <p>SPF＜30</p>
                </div>
                <div class="radio2">
                  <div class="circle"></div>
                  <p>30≤SPF＜50</p>
                </div>
                <div class="radio3">
                  <div class="circle"></div>
                  <p>SPF≥50</p>
                </div>
                <div class="radio4">
                  <div class="circle"></div>
                  <p>暂无</p>
                </div>
              </div>
            </div>
          </el-collapse-item>

          <el-collapse-item name="3">
            <template slot="title">
              <div class="selectStyle">
                <el-radio
                  v-model="radio"
                  :disabled="change_"
                  label="3"
                ></el-radio>
                <span
                  :style="{
                    color:
                      radio == 3
                        ? 'rgba(29, 92, 166, 1)'
                        : 'rgba(24, 26, 29, 1)',
                  }"
                  >防晒分类</span
                >
              </div>
            </template>
            <div class="text3">
              <div class="radio">
                <div class="radio1">
                  <div class="circle"></div>
                  <p>物理</p>
                </div>
                <div class="radio2">
                  <div class="circle"></div>
                  <p>化学</p>
                </div>
              </div>
              <div class="radio3">
                <div class="circle"></div>
                <p>物化结合</p>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse> -->

          <!-- 图标描述 显示的状态  中文-->
          <div class="tip" v-show="showChart == 1 && $store.state.lang == 'cn'">
            <img class="img1" src="https://newstore.vynior.com/cpflx.png" alt="" />
            <div class="text2" @click="showChart = 0">{{ $t(`message.Category.common.Hide`) }}</div>
          </div>
          <!-- 图标描述 显示的状态  英文-->
          <div class="tip" v-show="showChart == 1 && $store.state.lang == 'en'">
            <img class="img1" src="https://newstore.vynior.com/Group%2033821%402x%20%281%29.png" alt="" />
            <div class="text2" @click="showChart = 0">{{ $t(`message.Category.common.Hide`) }}</div>
          </div>
          <!-- 图标描述 隐藏的状态 中文-->
          <div class="tips" v-show="showChart == 0 && $store.state.lang == 'cn'">
            <img src="https://newstore.vynior.com/Group%2033821.png" alt="" />
            <div class="text2" @click="showChart = 1">{{ $t(`message.Category.common.Show`) }}</div>
          </div>
          <!-- 图标描述 隐藏的状态 英文-->
          <div class="tips" v-show="showChart == 0 && $store.state.lang == 'en'">
            <img src="https://newstore.vynior.com/emptymiaos.png" alt="" />
            <div class="text2" @click="showChart = 1">{{ $t(`message.Category.common.Show`) }}</div>
          </div>
        </div>

        <!-- 右侧 内容图 -->
        <div class="content_data">
          <div class="empty_log" v-show="not_img">
            <img src=" https://newstore.vynior.com/kongs-1.png" alt="" />
            <div class="text">
              <p>{{ $t(`message.Category.page2.empty`) }}</p>
            </div>
          </div>

          <div class="default_img_data" v-show="not_img == 0">
            <img :src="default_img" alt="" mode="aspectFill" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { message } from '@/utils/resetMessage';
import { HttpClient } from '@/utils/http';
import Header from '@/components/Header';
import getFilename from '../../utils/getFilename';
export default {
  name: 'acategoty',
  components: {
    Header,
  },
  data() {
    return {
      category: '',
      seletshow: 0,
      downloadShow: false,
      info: 9,
      values: '', //下拉数值
      more_val: 0, //限制数量值
      change_: true,
      figure_up: [], //算法生成数据
      loading: false, //加载算法
      not_img: 1, //切换空页面
      default_img: '', //存储 选择 品类之后的 默认图
      panel_list: [],
      panel_data: [],
      idlist: [], //存储 选择对比产品的id数组
      label: '111111',
      contrast: [], //已选的对比产品数据
      disabled: true, //默认不让选择
      forms: '',
      value: false, //switch 滑块
      radio: 0, //单选模块
      product: '', //选择品类的数组
      formInline: { region: '', product: '' },
      formInlines: { region: '', product: '' },
      program_name: [], //存储项目名称
      product_name: [],
      // formInline0: [],
      category_list: [], //选择品类
      // program_name: [], //存储项目名称
      // product_name: [],
      // formInline0: [],
      pdc_c: { region: '', product: '' },
      category_arrs: [], ///产品品类数组
      options: [], //选择对比产品的数据
      taskID: '',
      downloadRole: false,
      showChart: 1, //控制图表描述的显示和隐藏
      // activeName: "1",
      activeNames: 0,
      showCollapse: false, //下拉的状态 是否可下拉
    };
  },
  watch: {
     "$store.state.lang":{
        handler(){
       
          console.log("监听切换");
            if (this.$store.state.lang == 'cn') {
                if(this.category_list[0] == "Sun care"){
           this.$set(this.category_list,0,"防晒霜")
        }
        if(this.product == "Sun care"){
             this.product = "防晒霜"
        }
    } else if (this.$store.state.lang == 'en') {
       if(this.category_list[0] == "防晒霜"){
           this.$set(this.category_list,0,"Sun care")    
        }
        if(this.product == "防晒霜"){
               this.product = "Sun care"
        }
    }
        }
      
      },
    'product': {
      handler(newv, oldv) {
        // console.log(newv);
        if (newv != '防晒霜' && newv != 'Sun care') {
          this.not_img = 1;
          this.default_img = '';
          return;
        }
        // if (newv != oldv) {
        //   this.req_products();
         
        //   this.req_imgs();
        // }
      },
    },
    default_img: {
      handler(newv) {
        if (newv != '') {
          this.not_img = 0;
        }
        if (newv == 'http://www.ht-fast.com/all_black.png') {
          this.showCollapse = false;
          console.log(this.showCollapse);
        } else {
          this.showCollapse = true;
          console.log(this.showCollapse);
        }
      },
    },
    figure_up: {
      handler(newv) {
        if (newv.length != 0) {
          this.loading = false;
          this.change_ = false;
          this.value = true;
          this.default_img = this.figure_up.classification[1];
        }
      },
    },
    value: {
      handler(newv, oldv) {
        console.log(newv);
        if (newv != oldv) {
          // if (
          //   newv == false &&
          //   (this.radio == 1 || this.radio == 2 || this.radio == 3)
          // ) {
          //   return;
          // } else if (newv == false) {
          //   this.default_img = this.figure_up.classification[1];
          // } else if (newv == true) {
          //   this.default_img = this.figure_up.classification[2];
          //   this.radio = 0;
          // }
          if (newv == false) {
            this.default_img = this.figure_up.classification[0];
          } else {
            if (this.radio == 2) {
              this.default_img = this.figure_up.classification[2];
            } else if (this.radio == 3) {
              this.default_img = this.figure_up.classification[3];
            } else if (this.radio == 0) {
              this.default_img = this.figure_up.classification[1];
            }
          }
        }
      },
    },
    radio: {
      handler(newv, oldv) {
        if (newv != oldv) {
          // if (newv == 1) {
          //   this.value = false;
          //   this.default_img = this.figure_up.classification[3];
          // } else if (newv == 2) {
          //   this.value = false;
          //   this.default_img = this.figure_up.classification[4];
          // } else if (newv == 3) {
          //   this.value = false;
          //   this.default_img = this.figure_up.classification[5];
          // }
          if (newv == 2) {
            this.value = true;
            this.default_img = this.figure_up.classification[2];
          } else if (newv == 3) {
            this.value = true;
            this.default_img = this.figure_up.classification[3];
          } else if (newv == 0) {
            this.value = true;
            this.default_img = this.figure_up.classification[1];
          }
        }
        this.activeNames = newv;
      },
    },

    contrast: {
      handler(newv) {
        if (newv != '') {
          // this.not_img = 0;
          console.log('选择的数据是', newv);
        }
      },
    },
  },
  methods: {
    // FIXME
    radioChange(i) {
      if (this.radio == i) {
        this.radio = 0;
        this.seletshow = 0;
      } else {
        this.radio = i;
        this.seletshow = i;
      }
    },
    // 111
    changeRadio(e) {
      console.log(e);
      this.radio = e;
    },

    delete_val() {
      console.log(this.more_val);
      for (let index = 0; index < this.more_val; index++) {
        // this.contrast.pop()
        this.idlist.pop();
      }
      console.log(this.contrast);
      console.log(this.idlist);
    },

    // NOTE下载图表
    async downloadIMG() {
      if (this.taskID == '') {
        message({
          showClose: true,
          message:this.$t(`message.itemAnalyse.analyse`),
          type: 'warning',
          customClass: 'change_bar',
        });
        return;
      }
      this.downloadRole = true;
      this.loading = true;
      let location;
      if (this.value == false) {
        location = 0;
      } else {
        if (this.radio == 0) location = 1;
        if (this.radio == 2) location = 2;
        if (this.radio == 3) location = 3;
      }
      // console.log(location);
      let response = await HttpClient.post(
        'CatAna_task/classification_download',
        {
          // type: "classification",
          language:this.$store.state.lang,
          taskid: this.taskID,
          location,
        },
        { responseType: 'arraybuffer' }
      );
      const blob = new Blob([response.data], {
        type: 'application/zip',
      });
      let name = getFilename(response);
      let downloadElement = document.createElement('a');
      let classificationhref = window.URL.createObjectURL(blob);
      downloadElement.href = classificationhref;
      downloadElement.download = `${name}`;
      // console.log("blob :>> ", blob);
      document.body.appendChild(downloadElement);
      downloadElement.click();
      document.body.removeChild(downloadElement);
      window.URL.revokeObjectURL(classificationhref);
      this.loading = false;
      this.downloadRole = false;
    },
    async downloadIMGALL() {
      if (this.taskID == '') {
        message({
          showClose: true,
          message:this.$t(`message.itemAnalyse.analyse`),
          type: 'warning',
          customClass: 'change_bar',
        });
        return;
      }
      this.downloadRole = true;
      this.loading = true;
      let response = await HttpClient.post(
        'CatAna_task/classification_downloadall',
        {
          // type: "classification",
          taskid: this.taskID,
          language:this.$store.state.lang
          
        },
        { responseType: 'arraybuffer' }
      );
      const blob = new Blob([response.data], {
        type: 'application/zip',
      });
      let name = getFilename(response);
      let downloadElement = document.createElement('a');
      let classificationhref = window.URL.createObjectURL(blob);
      downloadElement.href = classificationhref;
      downloadElement.download = `${name}`;
      // console.log("blob :>> ", blob);
      document.body.appendChild(downloadElement);
      downloadElement.click();
      document.body.removeChild(downloadElement);
      window.URL.revokeObjectURL(classificationhref);
      this.loading = false;
      this.downloadRole = false;
    },
    // 请求用户的产品品类
    async req_category() {
      let res = await HttpClient.post('subuser/getUesrProduct', {
        _id: this.$store.state.userid,
      });
      console.log(res);

      this.category_list = res.classification;
      if (this.$store.state.lang == 'cn') {
        console.log('this.category_list CN:>> ', this.category_list);
        this.category_list.forEach((item, index) => {
          console.log(item);
          switch (item) {
            case 'Sun care':
              this.$set(this.category_list, index, '防晒霜');
              break;
            case 'Toner':
              this.$set(this.category_list, index, ' 爽肤水');
              break;
            case ' Lotion':
              this.$set(this.category_list, index, '乳液');
              break;
            case 'Hand lotion':
              this.$set(this.category_list, index, '护手霜');
              break;
            default:
              break;
          }
        });
      } else {
        console.log('this.category_list EN :>> ', this.category_list);
        this.category_list.forEach((item, index) => {
          console.log(item);
          switch (item) {
            case '防晒霜':
              // item = 'Sun care';
              this.$set(this.category_list, index, 'Sun care');
              break;
            case '爽肤水':
              item = 'Toner';
              this.$set(this.category_list, index, 'Toner');
              break;
            case '乳液':
              item = 'Lotion';
              this.$set(this.category_list, index, 'Lotion');
              break;
            case '护手霜':
              item = 'Hand lotion';
              this.$set(this.category_list, index, 'Hand lotion');
              break;
            default:
              break;
          }
        });
      }
      // console.log(this.program_name);
    },

    //请求默认图片地址
    async req_imgs() {
      HttpClient.post('CatAna_task/before_classification', {
        category: this.product,
      }).then((res) => {
        console.log(res);
        this.default_img = res.result;
      });
    },

    //分析接口
    async req_ana() {
      if (this.contrast.length == 0) {
        message({
          showClose: true,
          message: this.$t(`message.Message.selectCP`),
          type: 'warning',
          customClass: 'change_bar',
        });
      } else {
        this.loading = true;
        HttpClient.post('CatAna_task/classification', {
          account_id: this.$store.state.userid,
          user_id: this.$store.state.userid,
          category: this.product,
          productlist: this.idlist,
        }).then((res) => {
          console.log(res);
          console.log(this.figure_up);
          this.figure_up = res.result;
          this.taskID = res.result._id;
          console.log(this.figure_up);
        });
      }
    },
    // !分析接口 中英文切换
    async req_anas() {
        HttpClient.post('CatAna_task/classification', {
          account_id: this.$store.state.userid,
          user_id: this.$store.state.userid,
          category: this.product,
          productlist: this.idlist,
        }).then((res) => {
          console.log(res);
          console.log(this.figure_up);
          this.figure_up = res.result;
          this.taskID = res.result._id;
          console.log(this.figure_up);
        });
      },
    changeName(e) {
      console.log(e);
      this.category = e;
      if (e == 'Sun care') {
        this.category = '防晒霜';
      } else {
        this.category = e;
      }
      console.log('this.category :>> ', this.category);

      this.req_products();
    },
    //获取 对比产品数据
    req_products() {
      HttpClient.post('program/history_list', {
        user_id: this.$store.state.userid,
        category: this.category,
      }).then((res) => {
        console.log(res);
        let newlist = res.result;
        console.log(newlist);
        let labellist = [];
        newlist.forEach((item) => {
          console.log(item);
          labellist.push(item.label);
          console.log(item.children);
        });
        console.log(labellist);

        this.options = newlist;
        console.log(this.options);
      });
    },

    // 搜索显示  感官数据
    async Cvalue(e) {
      console.log(e);
      // if (e.length > 10) {
      //   message({
      //     showClose: true,
      //     message: "最多只能选择10个对比产品哦！",
      //     type: "warning",
      //     customClass: "change_bar"
      //   });
      //   this.$nextTick(() => {
      //     this.$refs.cascaderAddr.checkedValue = this.values;
      //     this.$refs.cascaderAddr.computePresentContent();
      //   });
      //   return;
      // }
      this.values = e;
      this.idlist = [];
      let namelist = [];
      let RadarName_List = [];
      // let arr = { name: "", label: "" };
      this.panel_data = [];
      e.forEach((itemo) => {
        // console.log(itemo);
        // console.log(this.options[itemo[0]].children[itemo[1] - 1].productid);
        this.idlist.push(this.options[itemo[0]].children[itemo[1] - 1].productid);

        namelist.push(this.options[itemo[0]].children[itemo[1] - 1].label);
        RadarName_List.push(this.options[itemo[0]].label);
      });
      console.log(this.idlist); //获取到的产品id 数组
      console.log(namelist); //获取到的产品名 数组
      console.log(RadarName_List); //获取到的产品名 数组  对应的 主产品名

      this.req_pdResarch();
    },
    //获取分析结果（不是开始分析）
    async req_pdResarch() {
      HttpClient.post('CatAna_task/find', {
        cond: {
          id: this.$store.state.userid, //客户ID
          category: this.product, //产品品类
          type: '2', //分析类别（1产品分类2产品特征3产品轮廓4产品重要度）
          productlist: this.idlist, //产品id数组（重要度分析无，能选产品的有，就是type为4的不要传这个）
        },
      }).then((res) => {
        console.log(res);
      });
    },

    //清楚选项
    clear_num() {
      this.contrast = [];
      // this.$refs['cascaderAddr'].panel.clearCheckedNodes();
    },
  },
  mounted() {
    this.req_category();
    document.addEventListener('click', (e) => {
      if (this.downloadShow == true) {
        if (e.target.id != 'downloadImg') {
          this.downloadShow = false;
        }
      }
    });
  },
};
</script>

<style lang="less" scoped>
@import url('../../style/Acategory/Acategoty.less');
</style>
